import React, { useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import { IconButton } from "@mui/material";
import Softphone from "./Softphone";

const AudioContextButton = () => {
  const [audioContext, setAudioContext] = useState(null);
  const [isActive, setIsActive] = useState(false);

  const handleAudioContextStart = () => {
    if (!audioContext) {
      // @ts-ignore
      const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
      // @ts-ignore
      setAudioContext(newAudioContext);

      newAudioContext
        .resume()
        .then(() => {
          console.log("AudioContext started.");
          setIsActive(true);
        })
        .catch((error) => {
          console.error("Error starting AudioContext:", error);
        });
    } else {
      // @ts-ignore
      if (audioContext.state === "suspended") {
        audioContext
          // @ts-ignore
          .resume()
          .then(() => {
            console.log("AudioContext resumed.");
            setIsActive(true);

          })
          // @ts-ignore
          .catch((error) => {
            console.error("Error resuming AudioContext:", error);
          });
      }
    }
  };

  return (
    <>
      <IconButton color={isActive ? "primary" : "default"}
                  onClick={handleAudioContextStart}
                  aria-label="Activate Microphone">
        <MicIcon />
      </IconButton>
      {isActive && <Softphone />}
    </>
  );
};

export default AudioContextButton;
