import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import React from "react";
import AudioContextButton from "../../TwilioVoice/AudioContext";

type CallModalProps = {
  open: boolean;
  handleClose: Function;
};

function CallModal({ open, handleClose = () => {} }: CallModalProps) {

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Anrufen"}
    >
      <AudioContextButton />
      <p className={"center"}>
        Bitte Microfon Button drücken, um das Mikrofon zu aktivieren.
        Danach kann man eine beliebige Telefonnummer im Format +49123456789 eingeben, um den Anruf zu starten.
      </p>
    </LegalbirdIoModal>
  );
}

export default CallModal;
