import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import ButtonLoading from "../../Button/ButtonLoading";
import React, { useState } from "react";
import { AbstractCase } from "../../../types/AbstractCase";
import { Editor } from "react-draft-wysiwyg";
import ApiClient from "../../../services/ApiClient";
import { editorStateToHTML, htmlToEditorState } from "../../../services/Editor/editorFunctions";
import moment from "moment";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";

type CreateActivityNoteModalProps = {
  product: AbstractCase;
  open: boolean;
  closeDialog: Function;
  updateActivities: Function;
};

export default function CreateActivityNoteModal({
  open,
  closeDialog,
  product,
  updateActivities,
}: CreateActivityNoteModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = useState(getInitialEditorState());
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    setIsLoading(true);
    const backofficeCase = product.backofficeCase;

    try {
      await ApiClient.post("/activities", {
        body: JSON.stringify({
          case: backofficeCase["@id"],
          subject: "Externer Kundenberater Gesprächsnotiz",
          dueDate: moment(),
          dueTime: moment().format("HH:mm"),
          assignedUser: backofficeCase.accountManager ? backofficeCase.accountManager["@id"] : null,
          mandatory: true,
          type: "call_notes",
          note: editorStateToHTML(editorState, true),
          duration: 3,
          useActivityScheduling: true,
        }),
      });

      enqueueSnackbar("Gesprächsnotiz wurde für zuständigen Mitarbeiter gesetzt", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (e) {
      enqueueSnackbar("Es ist ein technischer Fehler aufgetreten, bitte versuchen Sie es erneut", {
        variant: "custom",
        isNonInteractive: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    await updateActivities();

    setIsLoading(false);
    handleClose();
  };

  const handleClose = () => {
    setEditorState(getInitialEditorState());
    closeDialog();
  };

  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Gesprächsnotiz erstellen"}
      submitButton={
        <ButtonLoading
          onClick={onSubmit}
          color="primary"
          variant={"contained"}
          isLoading={isLoading}
          disabled={!editorState.getCurrentContent().hasText()}
        >
          Speichern
        </ButtonLoading>
      }
    >
      <Typography sx={{ color: "red" }} align={"center"}>
        Achtung: Bitte <strong>nicht</strong> für reine Fallbeschreibungen nutzen, sondern nur{" "}
        <strong>konkrete To-Dos</strong> für Legalbird eintragen!
      </Typography>

      <br />
      <Editor
        toolbar={{
          options: ["inline", "list", "link"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
        stripPastedStyles
        editorState={editorState}
        onEditorStateChange={(editorState) => setEditorState(editorState)}
        placeholder={"Notiz"}
        localization={{ locale: "de" }}
        editorStyle={{
          minHeight: 250,
          backgroundColor: "#f8f4d0",
        }}
      />
    </LegalbirdIoModal>
  );
}

const getInitialEditorState = () => {
  return htmlToEditorState(
    "<p><strong>Anrufer (bitte ankreuzen):</strong> Mandant ( ) / Gegenseite ( ) / Gericht ( ) / Sonstiger Anrufer ( )</p>" +
      "<p><strong>Name des Anrufers:</strong></p>" +
      "<p><strong>Telefonnummer:</strong></p>" +
      "<p><strong>Anliegen:</strong></p>"
  );
};
