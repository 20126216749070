import React, { useEffect, useState } from 'react';
import { Device } from '@twilio/voice-sdk';
import {apiPost} from "../../services/Api/apiCall";

const Softphone = () => {
  const [token, setToken] = useState(null);
  const [device, setDevice] = useState(null);
  const [status, setStatus] = useState('Initializing...');
  const [call, setCall] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');

  const handleTokenFetch = async () => {
    try {
      const response = await apiPost('/twilio/get_token', {});
      setToken(response.token);
    } catch (err) {
      // @ts-ignore
      setError(`Failed to fetch token: ${err.message}`);
    }
  };

  useEffect(() => {
    handleTokenFetch().then();
  }, []);

  useEffect(() => {
    if (token) {
      const newDevice = new Device(token, {
        logLevel: 'debug', // or 'off'
      });

      newDevice.on('ready', () => {
        setStatus('Ready to make calls');
      });

      newDevice.on('error', (deviceError) => {
        setError(deviceError.message);
      });

      newDevice.on('incoming', (connection) => {
        // Optionally auto-accept or show a UI for incoming call
        connection.accept();
        setCall(connection);
        setStatus('In call with inbound caller');
      });

      newDevice.on('disconnect', () => {
        setCall(null);
        setStatus('Call ended');
      });

      // @ts-ignore
      setDevice(newDevice);
    }
  }, [token]);

  const makeCall = () => {
    if (!device || !phoneNumber) return;
    const params = {
      To: phoneNumber,
      caseId: '12345',
    };
    // @ts-ignore
    const outgoingCall = device.connect({ params });
    console.log('Outgoing call:', outgoingCall);
    outgoingCall.on('disconnect', () => {
      setCall(null);
      setStatus('Call ended');
    });
    setCall(outgoingCall);
    setStatus(`Calling ${phoneNumber}...`);
  };

  const hangUp = () => {
    if (call) {
      // @ts-ignore
      call.disconnect();
    }
  };

  return (
    <div className="softphone">
      <h1>Legalbird Chirper</h1>
      <div>Status: {status}</div>
      {error && <div style={{ color: 'red' }}>Error: {error}</div>}

      {!call && (
        <div>
          <input
            type="text"
            placeholder="Enter number to call"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button onClick={makeCall} disabled={!device || !phoneNumber}>
            Chirp
          </button>
        </div>
      )}

      {call && (
        <button onClick={hangUp}>
          Hang Up
        </button>
      )}
    </div>
  );
};

export default Softphone;
