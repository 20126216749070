import { Person } from "../types/Person";
import { translate } from "./Translations/translatorService";
import moment from "moment";
import { AbstractCase } from "../types/AbstractCase";
import { Address } from "../types/Address";

export function listAddress(address: Address) {
  return `${address.streetAddress}, ${address.postalCode} ${address.addressLocality}`;
}
export function listPersonWithAddress(person: Person, withBirthDate: boolean = false) {
  return `${translate("person.gender.values." + person.gender)}` +
     ` ${person.fullName}, ${withBirthDate ? `geboren am ${moment(person.birthDate).format("DD.MM.YYYY")}, ` : ""}wohnhaft ${listAddress(person.residenceAddress)}`;
}

export function listAllClients (product: AbstractCase) {
  let result = "";
  const allClients = [product.client].concat(product.additionalClients);
  allClients.forEach((client, index) => {
    if (index > 0) {
      result += index === allClients.length - 1 ? " und " : ", ";
    }
    result += `${ translate("person.gender.values." + client.gender)} ${client.fullName}`;
  });
  return result;
}

export const listViolations = (errors: Record<string, string>) => {
  return Object.values(errors).join('; ');
};
